import React from "react";
import MainLayout from "src/components/layoutmain";
import LayoutCentered from "src/components/layoutcentered";
import BackgroundDesign from "src/images/background_design_peoples2.png";
import stepCadreImage from "src/images/step_project_cadre-min.jpg";
import stepDesignImage from "src/images/step_project_design_user-min.jpg";
import stepDevelopImage from "src/images/step_project_develop-min.jpg";
import stepMaintainsImage from "src/images/step_project_maintain_cycle-min.jpg";
import numberapproch1 from "src/images/number_tain_1.svg";
import numberapproch2 from "src/images/number_tain_2.svg";
import numberapproch3 from "src/images/number_tain_3.svg";
import numberapproch4 from "src/images/number_tain_4.svg";
import visionRoiste from "src/images/vision_roiste.svg";
import {graphql, Link} from "gatsby";
import CardStepProject from "../components/cardstepproject";
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import moment from "moment/dist/moment";
import "moment/dist/locale/fr"

/**
 * Page d'accueil du site
 * @returns {JSX.Element}
 * @constructor
 */
export default function Home({data}) {

   moment.locale('fr')

   let articles = []

   if (data.allMarkdownRemark.edges.length > 0) {
      articles = data.allMarkdownRemark.edges.slice(0, 3)
   }

   function capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
   }


   return (
      <MainLayout>
         <main>
            <div className={"bloc-category bloc-presentation"}>
               <LayoutCentered className={"max-height"} size={"full"}>
                  <div className={"text-left"}>
                     <div>
                        <h1 className={"title"}>
                           AGENCE DIGITALE
                        </h1>
                        <h1 className={"subtitle"}>NOVATRICE <span>et</span> RESPONSABLE</h1>
                     </div>
                     <div className={"description"}>
                        <h2>Créateurs d'applications <br /><span>WEB & MOBILE</span></h2>
                        <div className="wrapper">
                           <div className="words">
                              <span>DURABLES</span>
                              <span>ÉCO-CONÇUES</span>
                              <span>SUR MESURE</span>
                              <span>DURABLES</span>
                           </div>
                        </div>
                     </div>
                  </div>
                  <img
                     className={"background"}
                     src={BackgroundDesign}
                     alt={
                        "Conception d'un site web écologique, accessible et économique"
                     }
                  />
               </LayoutCentered>

               <div className={"button-more-information"}>
                  <Link to={"/contact"} className={"button-more-yellow"}>DISCUTONS DE VOTRE PROJET</Link>
               </div>
            </div>

            <div className={"bloc-category bloc-step background-waves-four-color-duck"}>
               <LayoutCentered className={"max-height"} size={"full"}>
                  <div className={"bloc-content"}>
                     <h1 className={"title-accompaniement"}>
                        Accompagnement <span>Sur-Mesure</span>
                     </h1>

                     <p className={"description-accompaniement"}>
                        Un partenaire de <span className={"bold"}>confiance</span> pour répondre à vos
                        enjeux <span className={"bold"}>stratégique</span> et <span className={"bold"}>spécifique</span>.<br />
                        Nous vous accompagnons à chaque étape pour transformer vos idées en réussites grâce à
                        des applications
                        sur-mesure.
                     </p>

                     <div className={"container-row-card"}>
                        <CardStepProject image={stepCadreImage} title={"Cadrer <br/>vos projets"}
                                         alt={"Personnes travaillant en groupes"}>
                           <p>Adopter la meilleure stratégie qui fera la différence. <br/>
                              Donner les clés pour identifier et convertir vos idées et opportunités en outil
                              efficace</p>
                        </CardStepProject>
                        <CardStepProject image={stepDesignImage} title={"Concevoir auprès de vos utilisateurs"}
                                         alt={"Personne travaillant sur des schémas"}>
                           <p>Etre en adéquation avec vos utilisateur, définir vos cibles, comprendre leurs
                              usages et
                              identifiez les fonctionnalités clés.</p>
                        </CardStepProject>
                        <CardStepProject image={stepDevelopImage} title={"Développer vos applications"}
                                         alt={"Personne entrain de d'écrire des lignes de codes sur un ordinateur"}>
                           <p>Concevoir techniquement sur mobile et web votre application grâce aux
                              technologies adaptées et
                              des méthodes innovantes et efficientes.</p>
                        </CardStepProject>
                        <CardStepProject image={stepMaintainsImage} title={"Maintenir leurs cycles de vie"}
                                         alt={"Ordinateur avec une page de graphisme"}>
                           <p>Assurer la pérennité technique et le maintien en condition de votre application
                              pour un
                              fonctionnement optimal</p>
                        </CardStepProject>

                     </div>

                     <div className={"button-more-information"}>
                        <Link to={"/expertises/"} className={"button-more"}>ALLEZ, ON VOUS EN DIT PLUS</Link>
                     </div>
                  </div>
               </LayoutCentered>
            </div>

            <div className={"bloc-category bloc-approche background-waves-two-color-tan"}>
               <LayoutCentered className={"max-height"} size={"full"}>
                  <h1 className={"title-right"}>
                     Une approche
                     <span>Innovante & Performante</span>
                  </h1>

                  <div className={"text-centered"}>
                     <h2>LE <span>COEUR</span> DE NOTRE APPROCHE</h2>
                     <h3>Un ROI économique, environnemental et social positif !</h3>
                  </div>

                  <div className={"container-vision"}>
                     <div className={"row"}>
                        <div className={"items-row"}>
                           <div className={"header-items"}>
                              <img src={numberapproch1} alt={"numeros 1"}/>
                              <h2>DEMARCHE <br/>D'ECOCONCEPTION</h2>
                           </div>

                           <div className={"text-center"}>
                              <h4>Uniquement l’essentiel et bien fait</h4>
                              <p>
                                 Nous avons à coeur de nous différencier en intégrant les <span className={"bold"}>enjeux
                                 environnementaux</span> sur
                                 l’ensemble des phases de
                                 réalisation de nos applications en appliqueant au mieux
                                 le référentiel <span className={"bold"}>RGSEN</span>.</p>
                              <p><span className={"bold"}>Limiter</span> le surplus de fonctionnalités inutiles.</p>
                           </div>
                        </div>
                        <div className={"items-row"}>
                           <div className={"header-items"}>
                              <img src={numberapproch2} alt={"numeros 2"}/>
                              <h2>EXPERTISE TECHNIQUE <br/><span>CENTREE SUR LA DURABILITE</span></h2>
                           </div>

                           <div className={"text-center"}>
                              <h4>L’art de bien coder</h4>
                              <p>
                                 Tendre vers l’excellence technique en applicant des méthodes innovantes,
                                 agiles basées sur la
                                 qualité.</p>
                              <p>Pour <span className={"bold"}>moins de contraintes</span> et <span className={"bold"}>plus de productivités</span></p>
                              <p>Notre objectif, des applications avec <span className={"bold"}>un minimum de
                                 bug</span> et <span className={"bold"}>évolutives</span>.</p>
                           </div>
                        </div>
                     </div>

                     <div className={"row-centered"}>
                        <div className={"sticker"}>
                           <div className={"text-top-left"}>
                              <p>Une vision</p>
                              <p><span>ROI</span><span className={"thin"}>ste</span></p>
                           </div>
                           <img src={visionRoiste} alt={"icon plus"}/>
                           <div className={"text-bottom-right"}>
                              <p>Une vraie</p>
                              <p><span>transparence</span></p>
                           </div>
                        </div>
                     </div>

                     <div className={"row"}>
                        <div className={"items-row"}>
                           <div className={"header-items"}>
                              <img src={numberapproch3} alt={"numero 3"}/>
                              <h2>FOCUS EXPERIENCE <br/>UTILISATEUR</h2>
                           </div>

                           <div className={"text-center"}>
                              <h4>Apporter le maximum de
                                 valeur ajoutée</h4>
                              <p>Pensez utilisateur et produit.</p>
                              <p>Connaitre parfaitement les <span className={"bold"}>usages</span> et <span className={"bold"}>besoins</span> des utilisateurs
                                 pour résoudre des
                                 problèmes métiers et <span className={"bold"}>créer de la valeur</span> pour votre activité.</p>
                           </div>
                        </div>
                        <div className={"items-row"}>
                           <div className={"header-items"}>
                              <img src={numberapproch4} alt={"numero 4"}/>
                              <h2>VASTE <br/>ECOSYSTEME</h2>
                           </div>

                           <div className={"text-center"}>
                              <h4>Répondre à tous les enjeux</h4>
                              <p>
                                 S’entourer des <span className={"bold"}>meilleurs experts</span> des métiers du numérique responsable
                                 :</p>
                              <p>Conseil, pilotage stratégique, communication, graphisme, développeurs,
                                 testeurs...</p>
                           </div>
                        </div>
                     </div>
                  </div>

               </LayoutCentered>
            </div>

            <div className={"bloc-category bloc-valeurs background-waves-four-color-duck"}>
               <LayoutCentered className={"max-height"} size={"full"}>
                  <div className={"bloc-content"}>
                     <h1 className={"title"}>
                        Raison d'être <span>Valeurs</span>
                     </h1>

                     <p className={"description"}>
                        Une <span className={"bold"}>culture</span> et des <span className={"bold"}>valeurs fortes</span> <br/>au service d’un numérique
                        plus <span className={"bold"}>durable</span>
                     </p>

                     <div className={"block-value"}>
                        <h2 className={"header-title-left"}>AGENCE ENGAGEE</h2>
                        <h3>Promouvoir un numérique<br/>à impact positif</h3>

                        <p>Nous accompagnons les entreprises à aligner leur transformation digitale avec les
                           enjeux de
                           demain.</p>
                        <p>Nous voulons changer l’empreinte du digital.
                           Tous unis pour diffuser un numérique <span className={"bold"}>plus durable, plus inclusif, plus
                              accessible...</span></p>
                        <p>Accompagner chaque <span className={"bold"}>client</span> et <span className={"bold"}>salarié</span> à entreprendre les projets qui
                           l’épanouiront</p>

                        {/*<Link className={"button-more"} to={"/contact"}>ALLEZ, ON VOUS EN DIT PLUS ></Link>*/}
                     </div>

                     <div className={"block-value-right"}>
                        <h2 className={"header-title-right"}>TENDRE VERS L’EXCELLENCE TECHNIQUE </h2>
                        <h3>Pour un numérique plus<br /> durable & efficient </h3>

                        <p>Des méthodes basées sur le <span className={"bold"}>Craft</span> pour des applications avec un minimum de bug
                           et évolutive qui
                           répondent parfaitement aux demandes du client.</p>
                        <p>Eviter le développement <span className={"bold"}>rapide & sale</span> (quick & dirty) que l’on retrouve
                           souvent dans les
                           grands groupes.</p>
                        <p>Programmer <span className={"bold"}>rapidement</span> et <span className={"bold"}>efficacement</span> dans un contexte agile (BDD, DDD,
                           TDD, etc.)</p>
                        <p>Pour un <span className={"bold"}>ROI économique positif</span> sur le court et long terme.</p>
                        <p>Et <span className={"bold"}>SURTOUT, donner du sens</span> aux développeurs, qu’ils se sentent impliqués dans
                           la vie du
                           produit.</p>
                     </div>

                     <div className={"block-value"}>
                        <h2 className={"header-title-left"}>TRANSPARENCE A 100%</h2>
                        <h3>Faire confiance et être digne de confiance</h3>

                        <p>Avec nos clients, partenaires et collaborateurs nous nouons une relation
                           de <span className={"bold"}>confiance</span> et
                           de <span className={"bold"}>sincérité</span>, gage de <span className={"bold"}>sécurité</span> et <span className={"bold"}>d’épanousiement</span> de chacun.
                        </p>

                        <p>Construit autour d’engagements bilatérales qui sont renforcés par les feedbacks et la
                           résolution des
                           conflits entre pairs.</p>
                     </div>
                  </div>
               </LayoutCentered>
            </div>

            <div className={"bloc-category bloc-blog background-waves-three-color-tan"}>
               <LayoutCentered className={"max-height"} size={"full"}>
                  <h1 className={"title-right"}>
                     Notre Blog
                     <span>Nos dernières actus</span>
                  </h1>

                  <div className={"text-centered"}>
                     <p>Explorez l’univers du numérique pour <span className={"bold"}>maîtriser</span> les différents aspects de votre projet et
                        <span className={"bold"}> déployer</span> les
                        <span className={"bold"}> bonnes pratiques</span> pour des applications <span className={"bold"}>performantes</span>. Identifiez les <span className={"bold"}>opportunités</span> et les
                        <span className={"bold"}> facteurs clés</span> de succès grâce aux conseils d’experts et de retours d’expériences !</p>
                  </div>

                  <div className={"container-articles"}>
                     {
                        articles.map((article) => {
                           const timeReading = Math.round((article.node.html.replace(/(<([^>]+)>)/gi, "").split(" ").length) / 300 + 0.2)
                           if (article.node.frontmatter.title !== "not-showing") {
                              return (
                                 <div className={"article"}>
                                    <Link
                                       to={`/actualites/${constructPath(article.node.frontmatter.title)}`}
                                       className={"link"}>
                                       <div>
                                          <GatsbyImage alt={"thumbnail"}
                                                       image={getImage(article.node.frontmatter.thumb)}
                                                       className={"thumbnail"}/>
                                       </div>
                                       <div className={"content"}>
                                          <p className={"date"}>{moment(article.node.frontmatter.creation_date).format("DD MMMM YYYY")}</p>
                                          <p className={"text"}>{capitalizeFirstLetter(article.node.frontmatter.title)}</p>
                                          <div className={"footer"}>
                                             <div className={"article-link"}>Lire l'article ></div>
                                             <p>Lecture de {timeReading === 0 ? "1" : timeReading} min</p>
                                          </div>
                                       </div>
                                    </Link>
                                 </div>
                              )
                           }else{
                              return ""
                           }
                        })
                     }
                     {
                        articles.length <= 1 ?
                           <div className={"article-soon"}>
                              <h3>Bientôt disponible</h3>
                              <h4>...Et d'autres contenus en cours de rédaction</h4>
                           </div>
                           :
                           ""
                     }

                  </div>

                  <div className={"content-link"}>
                     <Link to={"/actualites/"} className={"button-more"}>Parcourir nos contenus</Link>
                  </div>
               </LayoutCentered>
            </div>

            <div
               className={"bloc-category bloc-contact background-waves-four-color-white"}>
               <LayoutCentered className={"max-height"} size={"full"}>
                  <h1 className={"title"}>
                     Contactez-nous&nbsp;!
                  </h1>
                  <div className={"container-contact"}>
                     <div className={"element-contact"}>
                        <h3>Vous avez un projet ?</h3>
                        <p>
                           Vous êtes porteur de projet, en phase d’accélération
                           pour votre startup ou en recherche de productivité ?
                        </p>
                        <Link to={"/contact"}>Discutons en, ensemble</Link>
                     </div>
                     <div className={"element-contact"}>
                        <h3>Le job de vos rêves ?</h3>
                        <p>
                           Tu souhaites intégrer l’aventure Numeko et réaliser des projets ayant du sens.
                        </p>
                        <Link to={"/carrieres/"}>C'est ici qu'il se trouve</Link>
                     </div>
                     <div className={"element-contact"}>
                        <h3>Discuter ?</h3>
                        <p>
                           On aime partager et échanger en visio ou en physique.
                        </p>
                        <Link to={"/contact"}>Démarrer la discussion</Link>
                     </div>
                  </div>
               </LayoutCentered>
            </div>
         </main>
      </MainLayout>
   );
}


export const query = graphql`
query HomePage {
  allMarkdownRemark(
      sort: {fields: frontmatter___creation_date, order: DESC}
      filter: {frontmatter: {status: {eq: "En ligne"}}}
  ) {
    edges {
      node {
        frontmatter {
          creation_date
          title
          description
          thumb {
            childImageSharp {
              gatsbyImageData
            }
          }
          tags
          status
          author {
            first_name
            last_name
          }
        }
        id
        html
      }
    }
  }
}
`;

function constructPath(title) {
   return title.toLowerCase().replaceAll(" ", "-")
}
